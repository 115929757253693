import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import lawBookIllustration from "../images/law-book-illustration.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords = { [`LawChest`, `home`] }
        title = "Home"
      />

      <section className="text-center">
        <img
          alt="Law Book"
          className="block mx-auto w-1/3"
          src={lawBookIllustration}
        />

        <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Welcome to LawChest!
        </h2>

        <p className="leading-loose">
          A War Chest for Australian Lawyers
        </p>
      </section>
    </Layout>
  );
}

export default IndexPage;
